import React, { FC } from 'react';
import classNames from 'classnames';
import { useExperiments } from '@wix/yoshi-flow-editor';
import { ImageService, EmptyPicture } from '@wix/members-area-commons-ts';
import { MediaPlatformImage } from '@wix/members-domain-ts';
import { WowImage, ImageResizeOptions } from 'wix-ui-tpa';

import {
  Nullable,
  ProfileImage,
  InjectedClassNameProps,
  InjectedDataHookProps,
  Experiments,
} from '../../../types';
import styles from './Avatar.scss';

interface DefaultPictureProps {
  layoutType: ProfileImage;
}

interface UserPictureProps {
  url: string | MediaPlatformImage;
  pictureHeight: number;
  userName: Nullable<string>;
  shouldDisableWowImage: boolean;
}

type AvatarProps = DefaultPictureProps &
  InjectedDataHookProps &
  InjectedClassNameProps & {
    name: Nullable<string>;
    url: Nullable<string | MediaPlatformImage>;
    pictureHeight: number;
    shouldDisableWowImage?: boolean;
  };

const isLayoutRound = (layoutType: ProfileImage) =>
  layoutType === ProfileImage.Round;

const DefaultPicture: FC<DefaultPictureProps> = ({ children, layoutType }) => (
  <div className={classNames(styles.innerContainer, styles.background)}>
    <div className={classNames(styles.innerContainer, styles.overlay)}>
      <EmptyPicture className={styles.svg} />
      <div
        className={classNames(styles.innerContainer, {
          [styles.round]: isLayoutRound(layoutType),
        })}
      />
      <div
        className={classNames(styles.innerContainer, styles.innerOverlay, {
          [styles.round]: isLayoutRound(layoutType),
        })}
      />
      {children}
    </div>
  </div>
);

const UserPicture: FC<UserPictureProps> = ({
  children,
  url,
  pictureHeight,
  userName,
  shouldDisableWowImage,
}) => {
  const { experiments } = useExperiments();
  const imageURL = typeof url === 'object' ? url.file_name : url;
  const sourceWidth = typeof url === 'object' ? url.width : pictureHeight;
  const sourceHeight = typeof url === 'object' ? url.height : pictureHeight;

  const isAvatarWowImageEnabled = experiments.enabled(
    Experiments.AvatarWowImage,
  );

  if (isAvatarWowImageEnabled && !shouldDisableWowImage) {
    return (
      <>
        <WowImage
          sourceWidth={sourceWidth}
          sourceHeight={sourceHeight}
          src={imageURL}
          resize={ImageResizeOptions.cover}
          alt={userName ?? undefined}
          className={styles.wowImage}
          shouldUseLQIP
        />
        {children}
      </>
    );
  }

  return (
    <div
      className={classNames(styles.picture, styles.innerContainer)}
      style={{
        backgroundImage: `url(${new ImageService().getImageUrl({
          image: url,
        })})`,
      }}
    >
      {children}
    </div>
  );
};

const Avatar: FC<AvatarProps> = ({
  url,
  layoutType,
  children,
  className = '',
  dataHook,
  name,
  pictureHeight,
  shouldDisableWowImage = false,
}) =>
  layoutType === ProfileImage.None ? null : (
    <div
      data-hook={dataHook}
      className={classNames(className, styles.container, {
        [styles.round]: isLayoutRound(layoutType),
      })}
    >
      {url ? (
        <UserPicture
          userName={name}
          url={url}
          pictureHeight={pictureHeight}
          shouldDisableWowImage={shouldDisableWowImage}
        >
          {children}
        </UserPicture>
      ) : name ? (
        <div className={styles.nameLetter}>
          {name[0]}
          {children}
        </div>
      ) : (
        <DefaultPicture layoutType={layoutType}>{children}</DefaultPicture>
      )}
    </div>
  );

export default Avatar;
